// // =include _hamburger.js
// // =include _main-menu.js
// =include _utils.js
// =include datatables.js
// =include _datepicker.js
// =include _scale.js
// =include _new-scalevalue.js
// =include _copy-to-clipboard.js
// =include _results-filter.js
// =include _summernote.js
// =include _projectrelations.js
// =include _select2.js

$(document).ready(function () {
  $("body").height(window.innerHeight);
});
