"use strict";

(function ($) {
  const Scale = {
    init: () => {
      $("#id_type").on("change", function (e) {
        if ($(this).val() == 1) {
          $(".js-scale-cuantity").slideToggle();
        } else {
          $(".js-scale-cuantity").hide();
        }
      });
    },
  };

  $(document).ready(function () {
    Scale.init();

    if ($(".js-scale-cuantity-form").length) {
      $(".js-scale-cuantity").show();
    }
  });
})(jQuery);
