"use strict";

(function ($) {
  const ProjectRelationsForm = {
    init: () => {
      $(".c-project__relations-form").submit(function (e) {
        //e.preventDefault();
        $("#dataform").val(JSON.stringify(circus.getOutput()));
        if ($("#dataform").val() == "{}") {
          e.preventDefault();
        }
        return true;
      });
    },
  };

  $(document).ready(function () {
    if ($(".c-project__relations-form").length > 0) {
      ProjectRelationsForm.init();
    }
  });
})(jQuery);
