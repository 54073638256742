"use strict";
(function ($) {
  const Utils = {
    init: () => {
      $(".js-backhistory").click(function (e) {
        e.preventDefault();
        window.history.back();
      });

      // $(".js-hideshow-close").click(function (e) {
      //   e.preventDefault();
      //   $('.js-hideshow-element').hide();
      // });

      // $(".js-hideshow").click(function (e) {
      //   let target = e.currentTarget;
      //   $(".js-hideshow-element").hide();
      //   let element = $(target).data("hideshow");
      //   $(`#${element}`).slideDown();

      //   $("html, body").animate(
      //     {
      //       scrollTop: $(`#${element}`).offset().top,
      //     },
      //     1000
      //   );
      // });
    },
  };

  $(document).ready(function () {
    Utils.init();
  });
})(jQuery);
