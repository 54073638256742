"use strict";

(function ($) {
  const SummerNote = {
    init: () => {
      $('.summernote').summernote({
        height: 300,
        toolbar: [
          [ 'style', [ 'style' ] ],
          [ 'font', [ 'bold', 'italic', 'clear'] ],
          [ 'color', [ 'color' ] ],
          [ 'para', [ 'ol', 'ul', 'paragraph', 'height' ] ],
          [ 'insert', [ 'link'] ],
          [ 'view', [ 'undo', 'redo', 'fullscreen', 'codeview', 'help' ] ]
      ]
      });
    },
  };

  $(document).ready(function () {

    if ($(".summernote").length) {
      SummerNote.init();
    }
  });
})(jQuery);
