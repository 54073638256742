"use strict";

(function ($) {
  const NewScaleValue = {
    init: () => {
      $(".js-new-scalevalue-toggle").on("click", function (e) {
        $(".js-new-scalevalue").slideToggle();
      });
    },
  };

  $(document).ready(function () {
    NewScaleValue.init();
  });
})(jQuery);
