"use strict";

var CustomSelect2 = {
  init: function () {
    $(".js-custom-select2").select2();
  },
};

jQuery(document).ready(function () {
  if ($(".js-custom-select2").length) {
    CustomSelect2.init();
  }
});
