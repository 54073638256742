"use strict";

(function ($) {
  const CopyToClipboard = {
    init: () => {
      $(".js-copy-to-clipboard").on("click", function (e) {
        e.preventDefault();
        let urlProject = $(e.target).data("url");
        if (window.location.pathname.includes("/es/")) {
          urlProject = urlProject.replace("/en/", "/es/");
        }
        var aux = document.createElement("input");
        aux.setAttribute("value", urlProject);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
      });
    },
  };

  $(document).ready(function () {
    CopyToClipboard.init();
  });
})(jQuery);
