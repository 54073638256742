"use strict";

var CustomDatepicker = {
  init: function () {
    var customDatepicker = $(".js-datepicker");
    if ($(customDatepicker).length) {
      const optionsEn = {
        startDay: 1,
        disableYearOverlay: true,
        formatter: (input, date, instance) => {
          const value = `${date.getUTCFullYear()}-${date.getUTCMonth()+1}-${date.getDate()}`;
          input.value = value;
        },
      };

      const optionsEs = {
        customMonths: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        customDays: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
        startDay: 1,
        disableYearOverlay: true,
        formatter: (input, date, instance) => {
          const value = `${date.getUTCFullYear()}-${date.getUTCMonth()+1}-${date.getDate()}`;
          input.value = value;
        },
      };

      var options = optionsEn;
      var url = window.location.pathname;
      if (url.indexOf("/es/") != -1) {
        options = optionsEs;
      }

      //datepicker('.js-datepicker', optionsEs);
      var datepickerElements = document.getElementsByClassName("js-datepicker");
      Array.prototype.filter.call(datepickerElements, function (
        datepickerElement
      ) {
        datepicker(datepickerElement, options);
      });
    }
  },
};

jQuery(document).ready(function () {
  CustomDatepicker.init();
});
