"use strict";

(function ($) {
  const ResultsFilter = {
    init: () => {
      $("#thresholdFilter").change(function () {
        $(".thresholdHidden").val($("#thresholdFilter").val());
      });

      $("input[name=profiles]").change(function () {
        let values = Array();
        $("input[name=profiles]:checked").each(function () {
          values.push($(this).val());
        });
        $(".profilesHidden").val(values);
      });

      // Lo ejecutamos al inicio por si ya traía valores
      $(".thresholdHidden").val($("#thresholdFilter").val());
      let values = Array();
      $("input[name=profiles]:checked").each(function () {
        values.push($(this).val());
      });
      $(".profilesHidden").val(values);
    },
  };

  $(document).ready(function () {
    ResultsFilter.init();
  });
})(jQuery);
